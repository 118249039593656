import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { withStyles } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "../../components/material-ui/RadioGroup";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

import {
  SettingsMenuTabs, BackendSettings, SelectModel,
  SimilaritySettings, ImportFromDCSettings,
}                                     from "../../pages/dashboard/SettingsDialog";

import DateFnsUtils from "@date-io/date-fns";
import { subDays } from "date-fns";
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";

import {
  LANG,
}                                     from "../../../core/constants";
import {
  getRunConfigOption,
  showSuccess,
  showWarning,
  showError,
  isFileValidUTF8,
  isNLU,
  isGAI,
  isASR,
  mapObject,
  filterObject,
  getProject,
  isMaybeNLU,
  isMaybeASR,
  lcfirst,
  qw
}                                     from "../../../core/utils";
import {
  getData,
  uploadFile,
  postData,
  putData,
}                                     from "../../../core/fetchService";
import {
  changeDataset,
  updateDataset,
}                                     from "../../../features/settings";
import {
  getTestModelIntentAcc, getTestModelSlotAcc,
  getTestModelWRR, getTestModelGenvAcc,
}                                     from "../../../db_models";

import IconButton                     from "../../components/material-ui/IconButton";
import NumberField                    from "../../components/material-ui/NumberField";
import Autocomplete                   from "../../components/material-ui/Autocomplete";

import Upload                         from "../../components/fieldUpload";
import CloseBar                       from "../../components/dialogCloseBar";

import notBackdropClicked             from "../../components/helpers/notBackdropClicked";

import ComposerConfigDialog           from "./ComposerConfigDialog";


export const UploadVariant = Object.freeze({
  File: "file",
  Composer: "composer",
  Copy: "copy",
  Test: "test",
});

export const defaultDcImportConfig = Object.freeze({
  startDate: subDays(new Date(), 1),
  endDate: new Date(),
  quantity: 1,
  period: "month",
  status: "auto",
  role: "user",
  maxSize: 10000,
  ani: '',
  channel: '',
  first: true,
  glue: false,
  sanitize: false,
  update_intents: false,
  check_audio: true,
  date_type: "absolute",
});

const styles = (theme) => ({
  dialogPaper: {
    minWidth: "600px",
  },
  paper: {
    padding: "8px",
    textAlign: "left",
  },
  editLoadFromDCParamsRow: {
    marginTop: 10,
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '95%',
  },
  smallTextField: {
    marginTop: 8,
    marginLeft: 10,
    height: "auto",
    width: "25%",
  },
  dateTextField: {
    height: "auto",
    width: "50%",
  },
  column: {
    flexBasis: "33.33%",
  },
  heading: {
    fontSize: "12",
  },
  gridList: {
    overflow: "hidden",
  },
  summary: {
    marginLeft: "20px",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  checkboxStart: {
    width: "35%",
    marginRight: "10px",
    whiteSpace: "nowrap",
  },
  checkboxLabelBeforeText: {
    fontSize: "0.9rem",
    marginTop: 19,
  },
  checkboxLabelBeforeRadio: {
    fontSize: "0.9rem",
    marginTop: 13,
    width: 65,
  },
  similarityConfigServerURL: {
    marginTop: 0, // overwrite style from BackendSettings
    marginBottom: 8+2*2, // Settings::dialogField + 2*GridItem padding
  },
});

const useStyles = makeStyles(styles);

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <div
      id={`simple-tabpanel-${index}`}
      hidden={value != index}
      {...other}
    >
      <Typography component='span'>
        {children}
      </Typography>
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const getUploadFlagValues = state => [0,1,2,3].map(i => state.tabValue == i);
const isMenuItemKey = (state, k) => state.tabValue == undefined && state.menuItemKey == k;

function EditDialog(props) {
  const {
    rowId, classes, state, setState, checkStatus, setStatusWaiting, adjustStatusTo,
    fetchProjectDatasets, selectedDataSetId, getClearState_2,
    projects, projectId, projectDatasets, lang,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [is_from_file, is_from_dc, is_copy, is_from_test] = getUploadFlagValues(state);
  const is_generate = isMenuItemKey(state, 'item_from_llm');
  const is_similarity = isMenuItemKey(state, 'item_similarity');
  const project = getProject(projects, projectId);
  const is_proj_nlu = isNLU(project);
  const is_proj_gen = isGAI(project);

  const importFromComposer = async (rowId, button) => {
    const { dataSetName, description, composerConfig } = state;
    const {
      date_type,
      startDate,
      endDate,
      quantity,
      period,
    } = state.lfdcFormContent;

    const query = new URLSearchParams();
    query.append("date_type", date_type);
    if (date_type === "absolute") {
      query.append("startDate", new Date(startDate).toISOString());
      query.append("endDate", new Date(endDate).toISOString());
    } else if (date_type === "relative") {
      query.append("quantity", quantity);
      query.append("period", period);
    }
    [ "status", "role", "intent_slot", "ani", "channel",
      "maxSize", "first", "glue", "sanitize", "update_intents", "check_audio" ]
      .forEach(k => query.append(k, state.lfdcFormContent[k]));

    if (button == "save_import_settings") {
      const url = `/api/dataset/${rowId}/save-import-config?${query.toString()}`;
      return postData(url, { composerConfig }, dispatch, data => {
        checkStatus([{ _id: rowId }]);
        data.message && showSuccess(dispatch, t)(data.message);
      }, {
        error_prefix: t('datasets.save_import_settings_error'),
      });
    }

    const url = `/api/dataset/${rowId}/import_from_dc?${query.toString()}`;
    return postData(url, { composerConfig }, dispatch, data => {
      checkStatus([{ _id: rowId }]);
    }, {
      error_prefix: t('datasets.import_from_dc_error'),
    });
  };

  const uploadDataset = async rowId => {
    const {
      csvSeparator,
      csvExtIdField,
      csvInputField,
      csvOutputField,
      csvUtterance,
      csvIntentField,
      csvConfidence,
      csvAudio,
      header,
      radioValueFileType,
      dataSetName,
      description,
      unique,
      uploadProperties,
      annStatus,
    } = state;

    let formData = state.formData;
    if (!await isFileValidUTF8(formData.get('file'),
                               m => {showWarning(dispatch, t)(m); return true},
                               e => {showError(dispatch, t)(e);   return false}))
      return false;
    formData.append("project", projectId);
    dataSetName && formData.append("name", dataSetName);
    formData.append("description", description);
    formData.append("unique", unique);

    const encodeQueryData = data =>
      Object.keys(data)
        .map(key => encodeURIComponent(key) +"="+ encodeURIComponent(data[key]))
        .join("&");

    let queryString = {
      format: radioValueFileType,
      ...(is_proj_gen ? { type: 'generative' } : {}),
      ...uploadProperties,
      status: annStatus,
    };

    if (radioValueFileType == "csv") {
      const descr = {
        delimiter: csvSeparator,
        ...(is_proj_gen ? {
          id:     csvExtIdField,
          input:  csvInputField,
          output: csvOutputField,
        } : {
          text: csvUtterance,
          intent: csvIntentField,
        }),
        confidence: csvConfidence,
        ...(is_proj_gen ? {
        } : {
          audio: csvAudio,
        }),
        header: String(header || false),
      };

      queryString = { ...queryString, ...mapObject(descr, v => (v || '').trim()) };
    }

    const url = `/api/dataset/${rowId}/upload?`+ encodeQueryData(queryString);

    uploadFile(url, formData, dispatch, data => {
      checkStatus([{ _id: rowId }]);
    }, {
      t,
      message_prefix: 'datasets',
      error_prefix: t('datasets.upload_error'),
    });

    return true;
  }

  const saveDataset = async () => {
    const { rowId, dataSetName, description, unique, fileName, similarityConfig } = state;
    const { composerConfig, generationConfig } = state;

    if (rowId && fileName) {
        const dataset = projectDatasets.find(ds => ds._id == rowId);
        setStatusWaiting(dataset); // could have used 'Saving...' but just 'Waiting...' seems simpler
    }

    const sendData = rowId ? putData : postData;
    return await sendData('/api/dataset'+ (rowId ? '/'+ rowId : ''), {
      dataset: {
        name: dataSetName || fileName.replace(/.*[/\\]/).replace(/\.(json|txt|csv|tsv|xlsx?)$/, ''),
        description,
        ...(rowId ? {} : { unique }),
        project: projectId,
        similarityConfig,
        composerConfig,
        generationConfig,
      },
    }, dispatch, data => {
      /* When this request precedes the dataset loading request (/upload, /import_from_dc),
       * the dataset status blinks: X -> Waiting -> X(*) -> Preparing -> [next], where X is the initial status,
       * because this request returns (may return) X after Waiting has been sent.
       *
       * Therefore, we remove the (execution.)status field from the result (in case of successful request),
       * to avoid redundant changing status to X [marked with (*) above]
       */
      const adjust = rowId && fileName ? ({ project }) => {
        project.datasets = project.datasets.map(d => d._id == rowId? adjustStatusTo('Waiting...', d) : d);
      } : undefined;
      fetchProjectDatasets({ stat: false, adjust }).then(() => {
        // selects new (first) dataset if !selectedDataSetId else no-op
        dispatch(changeDataset(selectedDataSetId));
      });
      return data.dataset._id;
    });
  };

  const copyDataset = () => {
    const query = new URLSearchParams();
    for (const name of ["keep_intents", "keep_entities"])
      query.append(name, state.copyProps[name]);
    for (const name of ["start", "end"])
      if (state.copySlice[name] !== '')
        query.append(name, state.copySlice[name]);
    return postData(`/api/dataset/${state.rowId}/copy?`+ query, {}, dispatch, data => {
      fetchProjectDatasets({ stat: false });
    });
  };

  const importFromTest = rowId => {
    const url = `/api/dataset/${rowId}/import_from_test`;
    const uploadProperties = filterObject(state.uploadProperties, Boolean);
    return postData(url, { test_id: state.testId, ...uploadProperties }, dispatch, data => {
      fetchProjectDatasets({ stat: false });
    });
  };

  const generateDataset = rowId => {
    return postData(`/api/dataset/${rowId}/generate`, {}, dispatch, data => {
      fetchProjectDatasets({ stat: false });
    });
  };

  const handleClose = async (isAccepted, button) => {
    if (isAccepted) {
      let { rowId } = state;

      if (state.uploadVariant != UploadVariant.Copy && button != 'save_import_settings') {
        const res = await saveDataset();
        if (res?.error)
          return; // this error should have already been reported
        rowId = res;
      }

      if (state.tabValue != undefined)
        switch (state.uploadVariant) {
          case UploadVariant.File:
            if (state.formData && !await uploadDataset(rowId))
                return false;
            break;

          case UploadVariant.Composer:  importFromComposer(rowId, button); break;
          case UploadVariant.Copy:      copyDataset(); break;
          case UploadVariant.Test:      importFromTest(rowId); break;

          default: break; // avoid no-default-case warning
        }
      else if (is_generate)
        generateDataset(rowId);
    }

    setState(getClearState_2());
  };

  const simConfKeys = ['server', 'model'];            // TODO: from dataset descr
  const genConfKeys = ['server', 'model', 'dataset']; // TODO: same as above

  const GO_BUTTONS = [{
    id: 'save',
    cond: is_from_file && !state.fileName || is_copy,
    disabled: !state.dataSetName && !is_copy
  }, {
    id: 'load',
    cond: is_from_file && state.fileName || is_from_dc || is_from_test,
    disabled: is_from_dc && !state.dataSetName
    || is_from_file && state.radioValueFileType == "csv"
    && (!state.csvSeparator
        || is_proj_gen && !state.csvInputField
        || !is_proj_gen && !state.csvUtterance)
    || is_from_test && (!state.testId || !state.dataSetName),
  }, {
    id: 'generate',
    cond: is_generate,
    disabled: !state.dataSetName || !genConfKeys.every(k => state.generationConfig?.[k]),
  }, {
    id: 'save',
    cond: is_similarity,
    disabled: !state.dataSetName || !simConfKeys.every(k => state.similarityConfig?.[k]),
  }];

  return (
    <Dialog
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
      fullWidth={true}
      scroll="paper"
      open={true}
      onClose={notBackdropClicked(() => handleClose(false))}
      aria-labelledby="dialog-upload-title"
    >
      <CloseBar
        onClose={() => handleClose(false)}
        title={rowId ? t("datasets.dataset_edit") : t("datasets.dataset_upload")}
      />
      <DialogContent dividers={true}>
        <div style={{ display: 'flex' }}>
          <TextField
            id="dataSetName"
            label={t("common.name")}
            required
            size="small"
            variant="outlined"
            style={{ margin: "8px 0", flexGrow: 1 }}
            defaultValue={state.dataSetName}
            onChange={event => setState({ dataSetName: event.target.value })}
            autoFocus
          />
          <FormControlLabel
            label={t("datasets.unique")}
            style={{ marginLeft: 20, height: "auto" }}
            control={<Checkbox/>}
            disabled={Boolean(rowId)}
            checked={state.unique}
            onChange={event => setState({ unique: event.target.checked })}
          />
        </div>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          style={{ margin: "8px 0" }}
          id="description"
          label={t("common.description")}
          multiline
          rows="3"
          className={classes.textField}
          defaultValue={state.description}
          onChange={event => setState({ description: event.target.value })}
        />
        <DatasetSettings
          settings={state.similarityConfig}
          setSettings={(name, opts = {}) => data => {
            const value_obj = opts.is_direct ? data : { [name]: data.target.value };
            setState({ ...state, similarityConfig: { ...state.similarityConfig, ...value_obj }})
          }}
          {...{
            state, setState, is_proj_nlu, is_proj_gen,
            rowId, checkStatus, setStatusWaiting, adjustStatusTo,
            fetchProjectDatasets, selectedDataSetId, getClearState_2,
            projects, projectId, projectDatasets, lang,
          }}
        />
      </DialogContent>
      <DialogActions>
        {is_from_dc && rowId
          ? <div><Button onClick={() => handleClose(true, "save_import_settings")} color="primary">
            {t("datasets.save_import_settings")}
          </Button></div> : null
        }
        <div style={{ marginLeft: "auto" }}>
          <Button onClick={() => handleClose(false)}                   color="primary">
            {t("common.cancel")}
          </Button>
          {GO_BUTTONS.map(b => b.cond ? (
            <Button onClick={() => handleClose(true, b.id)}                 color="primary"
              key={b.id}
              disabled={b.disabled}
            >
              {t("common."+ b.id)}
            </Button>
          ) : null)}
        </div>
      </DialogActions>
    </Dialog>
  );
}

function UploadProperties({ state, handleUploadPropertiesChange, only, is_proj_gen }) {
  const { t } = useTranslation();

  return is_proj_gen ? null : (
    <div style={{ marginTop: 10 }}>
      <FormControl component="fieldset">
        <FormGroup>
          {Object.keys(state.uploadProperties).filter(k => !only || only.includes(k))
              .map(name => (
                <FormControlLabel
                  key={name}
                  control={
                    <Checkbox
                      checked={state.uploadProperties[name]}
                      onChange={handleUploadPropertiesChange}
                      name={name}
                    />
                  }
                  label={t("datasets."+ name)}
                />
              ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

function DatasetSettings({ settings, setSettings, ...props }) {
  const { is_proj_nlu, is_proj_gen, rowId, state, setState } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const { server } = settings?.nluConfig || {};
  const [is_from_file] = getUploadFlagValues(state);

  const [menuItemKey, _setMenuItemKey] = useState(is_from_file ? 'item_upload_file' : 'item_import_from_dc');
  const [nluConfigServer, setNLUConfigServer] = useState(server);

  useEffect(() => {
    if (menuItemKey == 'similarity')
      setNLUConfigServer(server);
  }, [menuItemKey]);

  const MENU = {
    upload: {
      is_group: true,
    },
    item_upload_file: {
      group: 'upload',
      props: { tabValue: 0, ...props },
      component: UploadDataset,
      fieldset_label: t('settings.menu_upload') +' '+ lcfirst(t('settings.menu_item_upload_file')),
    },
    item_import_from_dc: {
      group: 'upload',
      show_if: !is_proj_gen,
      props: { tabValue: 1, ...props },
      component: UploadDataset,
      fieldset_label: t('settings.menu_import_from_dc'),
    },
    item_from_dataset: {
      group: 'upload',
      show_if: getRunConfigOption('copyDatasetTab') && rowId,
      props: { tabValue: 2, ...props },
      component: UploadDataset,
    },
    item_from_test: {
      group: 'upload',
      show_if: is_proj_nlu || is_proj_gen,
      component: ImportFromTest,
      props: { tabValue: 3, is_proj_gen, ...props },
      fieldset_label: t('settings.menu_upload') +' '+ lcfirst(t('settings.menu_item_from_test')),
    },
 // generate: {
 //   is_group: true,
 // },
 // item_from_llm: {
 //   group: 'generate',
 //   show_if: is_proj_nlu,
 //   component: DatasetGenerateSettings,
 //   props: { nluConfigServer, setNLUConfigServer, ...props },
 // },
    settings: {
      is_group: true,
    },
    item_similarity: {
      group: 'settings',
      show_if: !is_proj_gen,
      component: DatasetSimilaritySettings,
      props: { nluConfigServer, setNLUConfigServer, ...props },
    },
  };

  const setMenuItemKey = k => {
    if (['item_upload_file', 'item_import_from_dc', 'item_from_dataset', 'item_from_test'].includes(k)) {
      const tabValue = MENU[k].props.tabValue;
      const uploadVariant = UploadVariant[Object.keys(UploadVariant)[tabValue] || 'File'];
      setState({ tabValue, uploadVariant });
    } else {
      setState({ tabValue: undefined, menuItemKey: k });
    }
    _setMenuItemKey(k);
  };

  const MENU_PROPS = mapObject(MENU, (m,k) => {
    const name = m.id +'Config';
    return {
      id: m.id,
      fields: m.fields,
      settings: settings?.[name],
      setSettings: (field_name, opts = {}) => data => {
        const value_obj = opts.is_direct ? data : { [field_name]: data.target.value };
        const other = (name == 'nluConfig' && field_name == 'server')
          ? { similarityConfig: { ...settings.similarityConfig, model: null, partition: null } }
          : {};
        setSettings({ ...settings, [name]: { ...settings[name], ...value_obj }, ...other });
      },
      ...m.props,
    };
  });

  return SettingsMenuTabs(MENU, MENU_PROPS, { menuItemKey, setMenuItemKey });
}

function UploadDataset({
  tabValue,
  settings,
  setSettings,
  state,
  setState,
  rowId, checkStatus, setStatusWaiting, adjustStatusTo,
  fetchProjectDatasets, selectedDataSetId, getClearState_2,
  projects, projectId, projectDatasets, lang,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const [is_from_file, is_from_dc, is_copy] = getUploadFlagValues(state);

  const project = getProject(projects, projectId);
  const is_proj_gen = isGAI(project);
  const is_proj_asr = isASR(project);

  const {
    radioValueFileType,
    uploadProperties: _up,
    copyProps,
  } = state;

  const uploadProperties = filterObject(_up, (_,k) =>
                                        (k != 'update_intents' || isMaybeNLU(project))
                                        && (k != 'check_audio' || isMaybeASR(project)));

  const handleChangeSwitch = (event) => {
    setState({
      radioValueFileType: event.target.value,
      annStatus: "auto",
    });
  };

  const handleCheckboxChange = (event, key) => {
    setState({
      [key]: {
        ...state[key],
        [event.target.name]: event.target.checked,
      },
    });
  };

  const handleUploadPropertiesChange = (event) => {
    handleCheckboxChange(event, 'uploadProperties');
  };

  const handleCopyPropertiesChange = (event) => {
    handleCheckboxChange(event, 'copyProps');
  };

  const onFileLoad = (fileName, formData) => {
    setState({ fileName, formData});
  };

  const handleCopySliceFill = (name, value) => {
    setState(prevState => ({
      copySlice: {
        ...prevState.copySlice,
        [name]: value,
      },
    }));
  };

  const handleLfdcFormContentFill = (name, value) => {
    const other = value
      ? (name == "sanitize" ? { update_intents: false }
         : name == "update_intents" ? { sanitize: false } : {})
      : {};
    setState(prevState => ({
      lfdcFormContent: {
        ...prevState.lfdcFormContent,
        [name]: value,
        ...other,
      },
    }));
  };

  const loadFromDialogComposerForm = (t, classes) => {
    return (
      <FormControl fullWidth component="fieldset">
        <RadioGroup
          style={{ display: "flex", marginLeft: "-16px" }}
          labels={['absolute','relative'].reduce((a,k) => {a[k]=t('datasets.'+ k +'_date'); return a}, {})}
          value={state.lfdcFormContent.date_type || "absolute"}
          onChange={event => handleLfdcFormContentFill("date_type", event.target.value)}
        />
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          {(state.lfdcFormContent.date_type || "absolute") == "absolute" ? (<>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={lang === LANG.RU ? ruLocale : enLocale}
            >
              <KeyboardDateTimePicker
                inputVariant="outlined"
                id="startDatePicker"
                ampm={false}
                disableFuture={true}
                minutesStep={5}
                className={classes.dateTextField}
                style={{ margin: 0, marginRight: 10 }}
                size="small"
                margin="normal"
                label={t("datasets.start_date")}
                okLabel={t("common.ok")}
                cancelLabel={t("common.cancel")}
                format="yyyy-MM-dd HH:mm"
                value={state.lfdcFormContent?.startDate || subDays(new Date(), 1)}
                onChange={(date) =>
                    handleLfdcFormContentFill("startDate", date)
                }
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={lang === LANG.RU ? ruLocale : enLocale}
            >
              <KeyboardDateTimePicker
                inputVariant="outlined"
                id="endDatePicker"
                ampm={false}
                className={classes.dateTextField}
                disableFuture={true}
                style={{ margin: 0 }}
                minutesStep={5}
                size="small"
                margin="normal"
                label={t("datasets.end_date")}
                okLabel={t("common.ok")}
                cancelLabel={t("common.cancel")}
                format="yyyy-MM-dd HH:mm"
                value={state.lfdcFormContent?.endDate || new Date()}
                onChange={(date) =>
                    handleLfdcFormContentFill("endDate", date)
                }
              />
            </MuiPickersUtilsProvider>
          </>) : state.lfdcFormContent.date_type == "relative" ? (<>
            <NumberField
              id="quantity"
              label={t("datasets.amount")}
              className={classes.dateTextField}
              style={{ marginRight: 10 }}
              value={state.lfdcFormContent?.quantity || ''}
              inputProps={{min: 1}}
              onChange={event => handleLfdcFormContentFill("quantity", event.target.value)}
            />
            <FormControl className={classes.dateTextField} size="small" variant='outlined'>
              <InputLabel id={`${t("datasets.period")}_label`}>{t("datasets.period")}</InputLabel>
              <Select
                label={t("datasets.period")}
                labelId="datasets_period_label"
                id="period"
                variant="outlined"
                value={state.lfdcFormContent?.period || ""}
                onChange={event => {
                  handleLfdcFormContentFill("period", event.target.value);
                }}
              >
                {["day", "week", "month", "year"].map(
                  (item, idx) => <MenuItem style={{ fontSize: "13px" }} key={`${item}_${Math.random()}`}
                    value={item}>{t('datasets.period_'+ item)}</MenuItem>)}
              </Select>
            </FormControl>
          </>) : null}
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <NumberField
            label={t("datasets.max_size")}
            className={classes.smallTextField}
            style={{ marginLeft: 0 }}
            value={state.lfdcFormContent?.maxSize}
            inputProps={{min: 1}}
            onChange={event => handleLfdcFormContentFill("maxSize", event.target.value)}
          />
        </FormGroup>
        {0 ? <FormGroup className={classes.editLoadFromDCParamsRow}>
          <InputLabel id={"role-select"}>{t("datasets.role")}</InputLabel>
          <Select
            labelId="role-select"
            label={t("datasets.role")}
            value={state.lfdcFormContent?.role}
            onChange={(event) => handleLfdcFormContentFill("role", event.target.value)}
          >
            <MenuItem value={"user"}>User</MenuItem>
            <MenuItem value={"bot"}>Bot</MenuItem>
          </Select>
        </FormGroup> : null}
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.intent_slot")}
            checked={state.intentSlotChecked}
            onChange={(event) => {
              const check = event.target.checked;
              setState((prevState) => ({
                intentSlotChecked: check,
                lfdcFormContent: {
                  ...prevState.lfdcFormContent,
                  intent_slot: check ? "dummy" : '',
                },
              }));
            }}
          />
          {state.intentSlotChecked ?
            <TextField
              required
              className={classes.smallTextField}
              style={{marginTop: 0}}
              defaultValue={state.lfdcFormContent?.intent_slot || ""}
              size="small"
              onChange={(event) => {
                handleLfdcFormContentFill("intent_slot", event.target.value);
              }}
              type="string"
              variant="outlined"
              fullWidth
            /> : null}
        </FormGroup>
        {isMaybeNLU(project) && <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.update_intents")}
            checked={state.lfdcFormContent?.update_intents}
            onChange={(event) =>
                handleLfdcFormContentFill("update_intents", event.target.checked)
            }
          />
        </FormGroup>}
        {isMaybeASR(project) && <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.check_audio")}
            checked={state.lfdcFormContent?.check_audio}
            onChange={(event) =>
                handleLfdcFormContentFill("check_audio", event.target.checked)
            }
          />
        </FormGroup>}
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.sanitize")}
            checked={state.lfdcFormContent?.sanitize}
            onChange={(event) =>
                handleLfdcFormContentFill("sanitize", event.target.checked)
            }
          />
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.ani")}
            checked={state.aniChecked}
            onChange={(event) => {
              setState({ aniChecked: event.target.checked });
              handleLfdcFormContentFill("ani", '');
            }}
          />
          {state.aniChecked ? <TextField
            className={classes.smallTextField}
            style={{marginTop: 0}}
            defaultValue={state.lfdcFormContent?.ani}
            size="small"
            onChange={(event) =>
                handleLfdcFormContentFill("ani", event.target.value)
            }
            type="string"
            variant="outlined"
            fullWidth
          /> : null}
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <FormControlLabel
            className={classes.checkboxStart}
            control={<Checkbox/>}
            label={t("datasets.channel")}
            checked={state.channelChecked}
            onChange={(event) => {
              setState({ channelChecked: event.target.checked });
              handleLfdcFormContentFill("channel", '');
            }}
          />
          {state.channelChecked ?
            <TextField
              className={classes.smallTextField}
              style={{marginTop: 0}}
              defaultValue={state.lfdcFormContent?.channel}
              size="small"
              onChange={(event) =>
                  handleLfdcFormContentFill("channel", event.target.value)
              }
              type="string"
              variant="outlined"
              fullWidth
            /> : null}
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow} style={{justifyContent: 'space-between'}}>
          <RadioGroup
            labels={['first','all'].reduce((a,k) => {a[k]=t('datasets.utterance_'+k); return a}, {})}
            value={state.lfdcFormContent?.first ? "first" : "all"}
            onChange={event => handleLfdcFormContentFill("first", event.target.value == "first")}
            legend={{name: t("datasets.utterances"), classes: classes.checkboxLabelBeforeRadio}}
          />
          <FormControlLabel
            control={<Checkbox/>}
            label={t(`datasets.glue_utterances`)}
            checked={state.lfdcFormContent?.glue}
            onChange={event => handleLfdcFormContentFill("glue", event.target.checked)}
          />
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <RadioGroup
            labels={['none','auto','manual'].reduce((a,k) => {a[k]=t('datasets.'+k); return a}, {})}
            value={state.lfdcFormContent?.status}
            onChange={event => handleLfdcFormContentFill("status", event.target.value)}
            legend={{name: t("datasets.annotation"), classes: classes.checkboxLabelBeforeRadio}}
          />
        </FormGroup>
        <FormGroup className={classes.editLoadFromDCParamsRow}>
          <ImportFromDCSettings
            id='composer'
            fields={['url', 'applicationId']}
            settings={state.composerConfig}
            setSettings={name => data => {
              setState({ composerConfig: { ...state.composerConfig, [name]: data.target.value } })
            }}
            fieldProps={{url: {style: {width: '100%'}}}}
          />
        </FormGroup>
      </FormControl>
    );
  };

  return (
    <>
        <TabPanel
          index={0}
          value={tabValue}
        >
          <div style={{ height: radioValueFileType == "json" ? 255 : is_proj_gen ? 315 : 415, overflowY: "auto" }}>
            <div style={{ marginBottom: 20, width: "100%", display: "flex" }}>
              <RadioGroup
                style={{ marginLeft: -16, width: "100%" }}
                name="position"
                labels={{json: "JSON", csv: "CSV"}}
                value={radioValueFileType}
                onChange={handleChangeSwitch}
              />
            </div>
            <div style={{ marginBottom: 20, textAlign: "left" }}>
              <Upload
                btnLabel={t('common.choose_file')}
                onFileLoad={onFileLoad}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <RadioGroup
                name="annotation"
                labels={['none','auto','manual'].reduce((a,k) => {a[k]=t('datasets.'+k); return a}, {})}
                value={state.annStatus}
                onChange={event => setState({ annStatus: event.target.value })}
                legend={{name: t("datasets.annotation"), classes: classes.checkboxLabelBeforeRadio}}
              />
              {radioValueFileType === "csv" && (
                <>
                  <div style={{ display: "flex" }}>
                    <FormLabel
                      className={classes.checkboxLabelBeforeText}
                      component="legend"
                    >
                      {t("datasets.separator")} *
                    </FormLabel>
                    <div>
                      <div style={{ display: "flex" }}>
                        <TextField
                          required
                          size="small"
                          className={classes.smallTextField}
                          id="csvSeparator"
                          onChange={(event) =>
                              setState({ csvSeparator: event.target.value })
                          }
                          variant="outlined"
                          fullWidth
                          defaultValue={state.csvSeparator}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <FormLabel
                      className={classes.checkboxLabelBeforeText}
                      component="legend"
                    >
                      {t("datasets.fields")}
                    </FormLabel>
                    <div>{(() => {
                      const FIELDS = {
                        ...(is_proj_asr ? {
                          audio:      'csvAudio'
                        } : {}),
                        ...(is_proj_gen ? {
                          input:      'csvInputField',
                          output:     'csvOutputField',
                          ext_id:     'csvExtIdField',
                        } : {
                          utterance:  'csvUtterance',
                          ...(is_proj_asr ? {} : {
                              intent:     'csvIntentField',
                          }),
                        }),
                        confidence: 'csvConfidence',
                        ...(is_proj_gen && !is_proj_asr ? {} : {
                          audio:      'csvAudio'
                        }),
                      };
                      return (
                        <div style={{ display: "flex" }}>
                          {Object.entries(FIELDS).map(([name, field]) => (
                            <TextField
                              key={name}
                              required={name == (is_proj_asr ? 'audio' : is_proj_gen ? 'input' : 'utterance')}
                              size="small"
                              className={classes.smallTextField}
                              id={field}
                              label={t("datasets."+ name)}
                              onChange={event => setState({ [field]: event.target.value })}
                              variant="outlined"
                              fullWidth
                              defaultValue={state[field]}
                            />))}
                        </div>
                      )})()}
                    </div>
                  </div>
                  <FormControlLabel
                    style={{ marginTop: 13, height: "auto" }}
                    control={
                      <Checkbox
                        checked={state.header}
                        onChange={() => setState(prevState => ({ header: !prevState.header }))}
                      />
                    }
                    label={t("datasets.fist_row_is_header")}
                  />
                </>
              )}
            </div>
            <UploadProperties
              {...{ state, handleUploadPropertiesChange, is_proj_gen }}
            />
          </div>
        </TabPanel>
        <TabPanel
          index={1}
          value={tabValue}
        >
          <div>
            {loadFromDialogComposerForm(t, classes)}
          </div>
        </TabPanel>
        {getRunConfigOption('copyDatasetTab') && rowId ? 
          <TabPanel
            index={2}
            value={tabValue}
          >
            {Object.keys(copyProps)
                .map(name => (
                  <FormControlLabel
                    key={name}
                    control={
                      <Checkbox
                        checked={copyProps[name]}
                        onChange={handleCopyPropertiesChange}
                        name={name}
                      />
                    }
                    label={t("datasets."+ name)}
                  />
                ))}
            <FormControl component="fieldset">
              <FormGroup>
                {Object.keys(state.copySlice)
                    .map(name => (
                      <NumberField
                        key={name}
                        label={t("datasets.slice_"+ name)}
                        className={classes.smallTextField}
                        style={{ marginTop: 10, width: '100%' }}
                        value={state.copySlice[name]}
                        inputProps={{min: 0}}
                        onChange={event => handleCopySliceFill(name, event.target.value)}
                      />
                    ))}
              </FormGroup>
            </FormControl>
          </TabPanel> : null
        }
      </>
  );
};

function DatasetSimilaritySettings({ nluConfigServer, setNLUConfigServer, settings, setSettings, state, setState }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div style={{ marginTop: 20 }}>
      <BackendSettings
        id='vectorization'
        fields={['server']}
        settings={state.similarityConfig}
        setSettings={(name, opts = {}) => data => {
          const value_obj = opts.is_direct ? data : { [name]: data.target.value };
          setState({ ...state, similarityConfig: { ...state.similarityConfig, ...value_obj }})
        }}
        fieldDescr={{ server: {
          type: 'nb_server',
          classes: classes.similarityConfigServerURL,
          onChange: setNLUConfigServer,
          info: true,
          classes_info: classes.similarityConfigServerURL,
        }}}
      />
      <SimilaritySettings
        settings={state.similarityConfig}
        setSettings={(name, opts = {}) => data => {
          const value_obj = opts.is_direct ? data : { [name]: data.target.value };
          setState({ ...state, similarityConfig: { ...state.similarityConfig, ...value_obj }})
        }}
        server={nluConfigServer}
      />
    </div>
  );
}

const EMPTY_GEN_CONFIG_DETAILS = { models: {} };

function DatasetGenerateSettings({ setNLUConfigServer, state, setState, projectDatasets }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [genConfDetails, setGenConfDetails] = useState();
  const [dataset, setDataset] = useState();

  const genConfig = state.generationConfig;
  const setGenConfig = value => {
    setState({ ...state, generationConfig: { ...state.generationConfig, ...value }})
  };

  return (
    <div style={{ marginTop: 20 }}>
      <BackendSettings
        id='generation'
        fields={['server']}
        settings={state.generationConfig}
        setSettings={(name, opts = {}) => data => {
          setGenConfig(opts.is_direct ? data : { [name]: data.target.value });
        }}
        fieldDescr={{ server: {
          type: 'nb_server',
          classes: classes.similarityConfigServerURL,
          onChange: setNLUConfigServer,
          info: true,
          classes_info: classes.similarityConfigServerURL,
        }}}
      />
      <SelectModel
        settings={genConfig || {}}
        onChange={(event, value) => setGenConfig({ model: value })}
        modelInfo={genConfDetails}
        setModelInfo={setGenConfDetails}
        EMPTY_MODEL_INFO={EMPTY_GEN_CONFIG_DETAILS}
        url={genConfig?.server && `/api/settings/similarity_config_details?server=${genConfig.server}`}
        getModelInfoFromResponse={data => data.similarity_config_details} // TODO: data.generation_config_details
        getOptions={modelInfo => Object.keys(modelInfo?.models || {})}
        getValue={modelInfo => modelInfo?.models[genConfig?.model] && genConfig?.model || null}
      />
      <Autocomplete
        label={t("common.dataset")}
        className={classes.textField}
        style={{marginTop: 20}}
        options={projectDatasets}
        getOptionLabel={d => d.name}
        value={projectDatasets.find(d => d._id == genConfig?.dataset) || null}
        onChange={(event, value) => setGenConfig({ dataset: value?._id })}
      />
    </div>
  );
}

function ImportFromTest({ state, setState, projectId, is_proj_gen }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [_tests, setTests] = useState([]);

  const getTests = () =>
    getData('/api/testmodel?project='+ projectId, dispatch, data => setTests(data.testmodels));

  useEffect(() => { getTests() }, []);

  const tests = _tests.filter(t => is_proj_gen ? getTestModelGenvAcc(t) != undefined
                              : (getTestModelIntentAcc(t) != undefined
                                 || getTestModelSlotAcc(t) != undefined));

  const handleCheckboxChange = (event, key) => {
    setState({
      [key]: {
        ...state[key],
        [event.target.name]: event.target.checked,
      },
    });
  };

  const handleUploadPropertiesChange = (event) => {
    handleCheckboxChange(event, 'uploadProperties');
  };

  return (<>
    <Autocomplete
      style={{ marginTop: 10 }}
      label={t('datasets.test')}
      options={tests}
      getOptionLabel={t => t.name}
      value={tests.find(t => t._id == state.testId) || null}
      onChange={(_, t) => setState({ testId: t?._id })}
    />
    <UploadProperties
      {...{ state, handleUploadPropertiesChange, only: qw`update_intents`, is_proj_gen }}
    />
  </>);
}

const mapStateToProps = (state) => ({
  projects: state.settings.projects,
  projectId: state.settings.projectId,
  projectDatasets: state.settings.projectDatasets,
  lang: state.settings.lang,
});

export default connect(mapStateToProps)(withStyles(styles)(EditDialog));
