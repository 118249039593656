import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation as _useTranslation, withTranslation as _withTranslation } from "react-i18next";
import { getProjectId, getProjectName, getSelectedDataSetId } from '../features/settings';
import { setMessageState } from '../features/messageInfo';
import { MESSAGE_STATUS, SOME_SETTINGS } from './constants';
import { postData, getRawData, fetchAndDownloadData } from "./fetchService";
import Location from './location';

const getUserInfo = () => JSON.parse(localStorage.getItem(SOME_SETTINGS.TOKEN));

export const GetUserName = () => getUserInfo()?.userName || '';
export const GetUserGroupsArray = () => getRunConfigOption('enableRoleModel') && getUserInfo()?.userGroups || [];
export const GetUserGroups = () => groupById(GetUserGroupsArray());
export const IsAdmin = () => !getRunConfigOption('enableRoleModel') || GetUserGroupsArray().find(g => g.name == 'admin');

export function GetToken() {
    return getUserInfo()?.token ?? null;
}

export function ClearToken() {
    console.log('ClearToken')
    localStorage.clear();
}

export const decodeMessage = (m, t, p) => {
  const MARKER = { START: 't(', END: ')' }; // Note: must match to BE
  const start_pos = m.indexOf(MARKER.START);
  const end_pos = m.indexOf(MARKER.END, start_pos + MARKER.START.length);
  if (start_pos != -1 && end_pos != -1) {
    const k = m.slice(start_pos + MARKER.START.length, end_pos);
    const tk = m.slice(start_pos, end_pos + MARKER.END.length);
    return m.replace(tk, t(p +'.'+ k));
  }
  return `[${p}] ${m}`;
};

const showMessage = type => (dispatch, t) => (message, ...args) =>
    dispatch(setMessageState({
        snackBarMessages: t ? t(message, ...args) : message,
        snackBarVariant: MESSAGE_STATUS[type],
        snackBarState: true,
    }));

export const showSuccess =  showMessage('SUCCESS');
export const showInfo =     showMessage('INFO');
export const showWarning =  showMessage('WARNING');
export const showError =    showMessage('ERROR');

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}

export function delay(fn, ms) {
    let timer = 0
    return function (...args) {
        clearTimeout(timer)
        timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
}

export function toFloat(val, accuracy = 2) {
  return !val || isNaN(val) ? 0 : parseFloat(val).toFixed(accuracy);
}

export function toInt(val) {
    if (!val) return 0
    let parsed = parseInt(val.toString().replace(/ /g, ''));
    return isNaN(parsed) ? 0 : parsed;
}

export const downloadData = (data, fname) => {
    // 1. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fname}`);
    // 2. Append to html page
    document.body.appendChild(link);
    // 3. Force download
    link.click();
    // 4. Clean up and remove the link
    link.parentNode.removeChild(link);
};

export const download = (url, dispatch, filename, data) => {
  const on_success = ({ data, response }) => {
    // determine file name
    const disp = response.headers.get('Content-Disposition');
    const fname = filename || (disp
                               ? disp.split('filename=')[1].slice(1,-1)
                               : 'noname_'+ new Date().toISOString().split('.')[0]);

    downloadData(data, fname);
  };

  return data
    ? fetchAndDownloadData(url, data, dispatch, on_success)
    : getRawData(url, dispatch, on_success);
}

export const getTableStickyParamsKeyNames = (projectId, datasetId, tableId) => {
    const projectPart = projectId ? `${projectId}_` : '';
    const dataSetPart = datasetId ? `${datasetId}_` : '';

    return {
        order: `${projectId}${dataSetPart}${tableId}_order`,
        orderBy: `${projectId}${dataSetPart}${tableId}_orderBy`,
        page: `${projectId}${dataSetPart}${tableId}_page`,
        pageRows: `${projectId}${tableId}_pageRows`,
    };
};

export const useStickyState = (defaultValue, key, enabled = true) => {
    const [value, setValue] = useState(() => {
      const stickyValue = window.localStorage.getItem(key);
      return enabled && stickyValue ? JSON.parse(stickyValue) : defaultValue;
    });
    useEffect(() => {
      if (value == undefined)
        window.localStorage.removeItem(key);
      else
        window.localStorage.setItem(key, JSON.stringify(value));
    },                                                                          [key, value]);
    return [value, setValue];
};

export const getRunConfigOption = name => {
  const id = 'runConfig.'+ name;
  return localStorage.hasOwnProperty(id) ? localStorage.getItem(id) : window["runConfig"][name];
}

/* Comparison of deps as in useEffect's second arg
 *
 * Warning: Should be called via .call()
 */
export function isChanged(prop_names) {
  let changed = false;
  prop_names.forEach(name => {
    if (!Object.is(this.state[name], this.props[name])) {
      this.setState({[name]: this.props[name]});
      changed = true;
    }
  });
  return changed;
}

export const cmpPossibleNumbers = (a,b) => {
  const isNumber = n => typeof n == 'string' && !isNaN(Number(n)) && n.trim();
  return isNumber(a) ? isNumber(b) ? a-b : -1 : isNumber(b) ? 1 : a.localeCompare(b);
};

export const showDate = d => d ? new Date(d).toLocaleString() : "";

export const wrapTranslation = (t) => {
  return { t: (key, ...params) => {
    const text = t(key);
    if (!params.length)
      return text;
    if (params.length == 1 && typeof params[0] == 'object')
      return Object.keys(params[0])
        .reduce((a,k) => a.replace(new RegExp(`{${k}}`, 'g'), params[0][k]), text);
    return params
      .reduce((a,v) => a.replace(/{}/,v), text);
  }};
};

export const useTranslation = () => {
  const { t } = _useTranslation();
  return wrapTranslation(t);
};

export const withTranslation = () => C =>
  _withTranslation()(props => <C {...{...props, ...wrapTranslation(props.t)}}/>);

export const ucfirst = s => s && (s[0].toLocaleUpperCase() + s.slice(1));
export const lcfirst = s => s && (s[0].toLocaleLowerCase() + s.slice(1));

export const getEnding = (w, n, t) =>
  t('endings.'+ t('word_endings.'+ w) +'_'+ [5,1,2,2,2,5,5,5,5,5][n%10]);

export const getPassiveEnding = (w, n, t) =>
  t('pp_endings.'+ t('word_endings.'+ w) +'_'+ [2,1,2,2,2,2,2,2,2,2][n%10]);

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => { ref.current = value });
  return ref.current;
};

export const useProjectDataset = (id, action, [proj, ds]) => {
  const p_proj = usePrevious(proj);
  const p_ds = usePrevious(ds);

  useEffect(() => { 
    if (id != 'projects' && p_proj && p_proj != proj)
      action('project');
  },                                                          [proj]);    // Explanation:
  useEffect(() => {                                                       // 1) for id==projects - disable both deps (project/dataset change)
    if (!['projects','datasets'].includes(id)
        && (p_proj && p_proj != proj || p_ds && p_ds != ds))
      action('dataset');
  },                                                          [ds]);      // 2) for id==datasets - disable only dataset change dep
};

export class GlobalState {
  constructor(update) {
    this.updateGlobalState = update;
  }

  componentDidMount() {
      this.updateGlobalState();
  }

  componentDidUpdate(prevProps, props) {
    if (prevProps.projectId != props.projectId)
      this.updateGlobalState();
  }
};

export const isArrayBufferValidUTF8 = (data, on_warn, on_error) => {
  if (!('TextDecoder' in window))
    return !on_warn || on_warn('common.warning_missing_text_decoder');
  const td = new TextDecoder('utf8', {fatal:true});
  try {
    td.decode(data);
  } catch {
    return on_error?.('common.error_invalid_utf8');
  }
  return true;
};

export const isFileValidUTF8 = async (file, on_warn, on_error) => {
  // TODO: possible optimization for big files: check file by parts
  const data = new Uint8Array(await file.arrayBuffer());
  return isArrayBufferValidUTF8(data, on_warn, on_error);
};

export const CHK_FAIL = m => {
  console.error(m);
};

/* note: same as in FE */
export const qw = (strings, ...args) =>
    strings.reduce((S,s,i) => S + args[i-1]+ s).trim().split(/\s+/);

/* note: same as in BE */
export const mapObject =     (obj, func) => Object.fromEntries(Object.entries(obj).map(([k,v]) => [k,func(v,k,obj)]));
export const filterObject =  (obj, cond) => Object.fromEntries(Object.entries(obj).filter(([k,v]) => cond(v,k,obj)));
export const filterKeys =    (obj, keys) => filterObject(obj, (v,k) => keys.includes(k));
export const filterOutKeys = (obj, keys) => filterObject(obj, (v,k) => !keys.includes(k));
export const renameKeys =  (obj, keymap) => Object.fromEntries(Object.entries(obj).map(([k,v]) => [keymap[k] ?? k, v]));
export const findEntry =     (obj, cond) => Object.entries(obj).find(([k,v]) => cond(v,k,obj));
export const findKey =       (obj, cond) => findEntry(obj, cond)?.[0];
export const findValue =     (obj, cond) => findEntry(obj, cond)?.[1];

export const mapArrayToObject = (arr, value) => arr.reduce((a,k) => { a[k] = value || true; return a }, {});

/*
export function groupBy(array, key) {
    return array.reduce((a, obj) => {
        const value = obj[key];
        a[value] = (a[value] || []).concat(obj);
        return a;
    }, {});
}
*/

/** group array by a unique key (repeated keys are ignored, last value is selected)
 * Note: exactly same as in FE (see: utils/misc.js) 
 */
export function groupById(array, key = '_id') {
    return array.reduce((a, obj) => {
        const value = obj[key];
        a[value] = obj;
        return a;
    }, {});
}

export function distinct(array, key) {
  return array && key ? [...new Set(array.map(x => x[key]))] : [];
}

export function copyName(obj) {
  return { name: obj.name +' (copy)' };
}

/* note: same as in BE */
export function fitToWidth(str, width, fitmiddle) {
    const elps = fitmiddle ? '..........' : '...';
    const el = elps.length;
    return str.length <= width ? str
        : width <= el ? '???'
        : fitmiddle? str.slice(0,width/2) + elps + str.slice(-width/2+el)
        : str.slice(0, width-el) + elps
}

export function jsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    const s = String(str);
    const l = 50;
    console.error(`error parsing json from '${fitToWidth(String(str),50)}': `, e);
    return undefined;
  }
}

export function EffectManager() {
  this.cancelled = false;
  this.cancel = () => { this.cancelled = true };
}

export const linkHeadCell = section => ({
  link: (row, cell) => e => { e.preventDefault(); Location.show(section, [row[cell._id]].flat().map(d => d._id)[0]); },
  viewData: data => [data || []].flat().map(d => d.name).join(', '),
  nohighlight: true,
});

export const optionalLinkHeadCell = s => ({ optional: true, ...linkHeadCell(s) });

export const subtractObject = (a, b) => filterObject(a, (v,k) => {
  if (v === b[k])
    return false;
  if (typeof v != 'object')
    return true;
  return JSON.stringify(v) != JSON.stringify(b[k]);
});

export const filterName = handleChange => event => {
  const { id, value } = event.target;
  handleChange({ target: { id, value: value.replace(/[^0-9A-Za-z-_.]/gi, '')} });
};

const getType = project => project?.type;

export const getProject = (projects, id) => projects.find(p => p._id == id);

export const isGAI = project => getType(project) == 'GAI';
export const isASR = project => getType(project) == 'ASR';
export const isNLU = project => getType(project) == 'NLU';

const maybe = isWhat => project => project && !project.type || isWhat(project);
export const isMaybeASR = maybe(isASR);
export const isMaybeNLU = maybe(isNLU);

export const isProperType = (project, obj_type) => project?.type
  ? isASR(project) == (obj_type == 'ASR_E2E') && (!isGAI(project) || obj_type == 'NLU_NB')
  : true;
export const isProperRuntimeType = (project, rt_type) => project?.type
  ? isASR(project) == (rt_type == 'ASR_E2E_Runtime') : true;

