import React, {
  useState, useEffect,
}                               from "react";
import { useDispatch }          from "react-redux";

import {
  DIALOG_USER_STATE, NLU_TYPES,
}                               from '../../../core/constants';

import { Grid }                 from "@material-ui/core";
import List                     from "@material-ui/core/List";
import ListItem                 from "@material-ui/core/ListItem";
import ListItemText             from "@material-ui/core/ListItemText";
import GridList                 from "@material-ui/core/GridList";
import GridListTile             from "@material-ui/core/GridListTile";
import TextField                from "@material-ui/core/TextField";
import Autocomplete             from "@material-ui/lab/Autocomplete";
import Button                   from "@material-ui/core/Button";

import { makeStyles }           from "@material-ui/core/styles";

import NumberField              from "../../components/material-ui/NumberField";
import ConfirmDialog            from "../../components/confirmDialog";

import {
  getData,
  postData,
  putData,
}                               from "../../../core/fetchService";
import {
  useTranslation,
  cmpPossibleNumbers,
  mapObject,
  filterObject,
  filterOutKeys,
  findEntry,
  EffectManager,
  getRunConfigOption,
}                               from "../../../core/utils";

const EMPTY_SIM_CONFIG_DETAILS = { models: {} };


const useStyles = makeStyles(theme => ({
  list: {
    width: '100%',
  },
  selected: {
    // '!important' to override styles coming with 'button' property
    backgroundColor: theme.palette.action.hover +' !important',
  },
  gridList: {
    /* width: '100%', */
    /* overflow: "hidden", */
  },
  textField: {
    marginTop: 8,
    marginBottom: 8,
  },
  dialogContent: {
    padding: '0 10px',
  },
  fieldset: { // from editModelDialog.js
    height: 360,
    overflow: 'auto',
    padding: '5px 25px',
    borderRadius: 5,
    borderWidth: 1,
  },
  legend: {
    marginLeft: '-10px',
    color: "#aaa",
  },
}));


export default function SettingsDialog({ onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [settings, setSettings] = useState();

  useEffect(() => {
    getData('/api/settings', dispatch, data => setSettings(data.settings[0] || {}));
  }, []);

  const handleClose = state => {
    if (state == DIALOG_USER_STATE.AGREE) {
      const url = '/api/settings'+ (settings._id ? '/'+ settings._id : '');
      const saveData = settings._id ? putData : postData;
      saveData(url, { settings }, dispatch);
    }
    onClose();
  };

  return settings ? (
    <ConfirmDialog
      title={t("common.settings")}
      content={<SettingsMenu settings={settings} setSettings={setSettings}/>}
      closeModal={handleClose}
      fullWidth
      maxWidth='md'
      contentProps={{
        className: classes.dialogContent,
      }}
    />
  ) : null;
}

export function SettingsMenu({ settings, setSettings, style = {} }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { train_url } = settings.nluConfig || {};

  const [menuItemKey, setMenuItemKey] = useState('backends');
  const [nluConfigServer, setNLUConfigServer] = useState(train_url);

  useEffect(() => {
    if (menuItemKey == 'similarity')
      setNLUConfigServer(train_url);
  }, [menuItemKey]);

  const MENU = {
    backends: {
      id: 'nlu',
      fields: ['train_url', 'deploy_url'],
      props: {
        fieldDescr: {
          train_url: { type: 'nb_server', onChange: setNLUConfigServer, info: true },
          deploy_url: { type: 'nb_runtime', info: true },
        },
      },
      component: BackendSettings,
    },
    similarity: {
      id: 'similarity',
      component: SimilaritySettings,
      props: {
        server: nluConfigServer,
      },
    },
    import_from_dc: {
      id: 'composer',
      fields: ['url', 'applicationId', 'token'],
      component: ImportFromDCSettings,
    },
  };

  const MENU_PROPS = mapObject(MENU, (m,k) => {
    const id = m.id || k;
    const name = id +'Config';
    return {
      id,
      fields: m.fields,
      settings: settings[name],
      setSettings: (field_name, opts = {}) => data => {
        const value_obj = opts.is_direct ? data : { [field_name]: data.target.value };
        const other = (name == 'nluConfig' && field_name == 'train_url')
          ? { similarityConfig: { ...settings.similarityConfig, model: null, partition: null } }
          : {};
        setSettings({ ...settings, [name]: { ...settings[name], ...value_obj }, ...other });
      },
      ...m.props,
    };
  });

  return SettingsMenuTabs(MENU, MENU_PROPS, { menuItemKey, setMenuItemKey, style });
}

export function SettingsMenuTabs(MENU, MENU_PROPS, { menuItemKey, setMenuItemKey, style = {} }) {
  const _MENU_KEYS = Object.keys(MENU).filter(k => MENU[k].show_if !== false);
  const MENU_KEYS = _MENU_KEYS.filter(k => !MENU[k].is_group || _MENU_KEYS.find(j => MENU[j].group == k));

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container wrap='nowrap' style={{ overflow: 'auto', ...style }}>
      <Grid item xs={3} sm={3} style={{ padding: 0, borderRight: 'solid 1px #eee' }}>
        <List className={classes.list}>
          {MENU_KEYS.map((k,i) => (
            <ListItem key={k} button
              className={k == menuItemKey ? classes.selected : ''}
              style={i ? { borderTop: 'none' } : {}}
              disabled={MENU[k].is_group}
              onClick={() => setMenuItemKey(k)}
            >
              <ListItemText
                primary={MENU[k].item_label || t('settings.menu_'+ k)}
                style={MENU[k].group ? {paddingLeft: 20} : {}}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={9} sm={9} style={{ padding: 10, paddingRight: 0 }}>
        {MENU_KEYS.map(k => {
          const MenuItem = MENU[k].component;
          return MENU[k].is_group ? null : (
            <fieldset key={k} className={classes.fieldset} hidden={k != menuItemKey}>
              <legend className={classes.legend}>{MENU[k].fieldset_label || t('settings.menu_'+ k)}</legend>
              <MenuItem {...MENU_PROPS[k]}/>
            </fieldset>
          )})}
      </Grid>
    </Grid>
  );
}

export function BackendSettings({ id, fields, settings, setSettings, fieldDescr = {} }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [savedValue, setSavedValue] = useState();

  useEffect(() => {
    Object.entries(fieldDescr).forEach(([name, d]) => settings?.[name] && d.onChange?.(settings[name]));
  }, []);

  const onPressEnter = action => event => {
    if (event.key == 'Enter')
      action(event);
  };

  const handleFocus = event => setSavedValue(event.target.value);
  const handleChange = onChange => async event => {
    const value = event.target.value;
    if (value && value != savedValue) {
      onChange(value);
      setSavedValue(value);
    }
  };

  const showTable = info => (
    <table style={{ width: '100%' }}><tbody>
      {Object.entries(info).map(([k,v]) => (
        <tr key={k}><td>{t(`settings.${k}`)}:</td><td>{v}</td></tr>
      ))}
    </tbody></table>);

  const getNBServerInfo = field_name => async setInfo => {
    const { [field_name]: url } = settings;
    if (!url)
      return setInfo('internal FE error: expected server url');

    const data = await getData('/api/settings/server_info?server='+ url, dispatch);
    if (data.error)
      return; // setInfo(t('settings.error_server_info', data.error)); off as the message shown in notification
    const d = data.server_info;
    if (!d)
      return setInfo('internal FE error: expected server_info field in response');

    const v = d.app_config?.e2e_version;
    const info = {
      version: d.version + (d.app_config ? '' : ' <!> incompatible with this NLU Suite version'),
      start_time: new Date(d.start_time).toLocaleString(),
      mp_enabled: d.app_config?.multiprocessing ? t('common.yes') : t('common.no'),
      asr_enabled: t(v ? 'common.yes' : 'common.no') + (v ? '! // e2efw v'+ v : ''),
      num_running_tasks: d.num_tasks?.running || 0,
    };
    setInfo(showTable(info));
  };

  const getRuntimeServerStatus = field_name => async setInfo => {
    const { [field_name]: url, [getRuntimeManagementKey(field_name)]: rt_url } = settings;
    if (!url)
      return setInfo('internal FE error: expected server url');

    const query = new URLSearchParams();
    query.append('server', rt_url || url);
    query.append('type', 'NLU_NB');
    query.append('is_mgmt', Boolean(rt_url));
    const data = await getData(`/api/runtime/list_models?${query}`, dispatch);
    if (data.error)
      return; // setInfo(t('settings.error_server_info', data.error)); off as the message shown in notification
    if (!Array.isArray(data))
      return setInfo('internal FE error: expected array in response');

    const info = {
      num_models: data.length,
    };
    setInfo(showTable(info));
  };

  const getRuntimeManagementKey = k => k.replace('deploy', 'manage');

  const getServerInfo = (field_name, type) => {
    const info = {
      nb_server: getNBServerInfo(field_name),
      nb_runtime: getRuntimeServerStatus(field_name),
    };
    if (!info[type])
      return () => alert(`Error: no getServerInfo func for type '${type}'`);
    return info[type];
  };

  const addRuntimeManagementURL = (fs, fd) => {
    const rt = findEntry(fd, v => v.type == 'nb_runtime');
    if (!rt)
      return [fs, fd];
    const [k,v] = rt;
    const { label_key, onChange } = fd[k].props || {};
    if (!getRunConfigOption('runtimeManagementURL')) {
      if (label_key)
        fd[k].props = { ...fd[k].props, label: t(label_key) };
      return [fs, fd];
    }
    const mk = getRuntimeManagementKey(k);
    const fields = [...fs, mk];
    const fieldDescr = fd;
    fieldDescr[mk] = {
      ...fieldDescr[k],
      props: {
        ...fieldDescr[k].props,
        id: mk,
        ...(label_key ? { label: t(label_key.replace('runtime', 'manage')) } : {}),
      },
    };
    fieldDescr[k] = {
      ...fieldDescr[k],
      info: false,
      props: {
        ...fieldDescr[k].props,
        onChange: event => { setSettings(k, { is_direct: true })({ [k]: event.target.value, [mk]: ''}) },
      },
    };
    if (label_key)
      fieldDescr[k].props = { ...fieldDescr[k].props, label: t(label_key.replace('runtime', 'runtime_predict')) };
    return [fields, fieldDescr];
  };

  [fields, fieldDescr] = addRuntimeManagementURL(fields, fieldDescr);

  return (
    <FieldSetSettings
      {...{ id, fields, settings, setSettings }}
      fieldProps={mapObject(fieldDescr, d => d.onChange ? {
        onFocus: handleFocus,
        onBlur: handleChange(d.onChange),
        onKeyDown: onPressEnter(handleChange(d.onChange)),
        ...d.props,
      } : d.props)}
      fieldClasses={mapObject(fieldDescr, d => ({ field: d.classes, info: d.classes_info }))}
      fieldButtons={mapObject(filterObject(fieldDescr, v => v.info), (field, name)  => ({
        getServerInfo: getServerInfo(name, field.type),
      }))}
    />
  );
}

function FieldSetSettings({
  id, fields, settings = {}, setSettings, fieldProps = {}, fieldClasses = {}, fieldButtons = {},
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const fieldClass = (name, kind) => [classes.textField, ...[fieldClasses[name]?.[kind] || []].flat()].join(' ');

  const [serverInfo, setServerInfo] = useState();

  return (
    <div className={classes.gridList} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      {serverInfo && <ConfirmDialog
        title={t("settings.server_info_title")}
        content={serverInfo}
        showBtnNameAgree={false}
        btnNameDisagree={t('common.ok')}
        closeModal={() => setServerInfo()}
        fullWidth
        maxWidth='xs'
      />}
      {fields.map(name => (
        <div key={name} style={{ display: 'flex', flexDirection: 'row', height: "auto" }}>
          <TextField
            label={t(`settings.${id}_${name}`)}
            size="small"
            variant="outlined"
            fullWidth
            className={fieldClass(name, 'field')}
            value={settings[name] ?? ''}
            onChange={setSettings(name)}
            {...fieldProps[name]}
          />
          {fieldButtons[name] && (
            <Button
              style={{ marginLeft: 10, width: 150 }}
              className={fieldClass(name, 'info')}
              disabled={!String(settings[name] || '').trim() || fieldButtons[name].disabled?.(settings[name])}
              variant="outlined"
              onClick={() => fieldButtons[name].getServerInfo(setServerInfo)}
            >
              {t("settings.server_info")}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}

export function SelectModel({
  settings = {},
  onChange,
  modelInfo,
  setModelInfo,
  EMPTY_MODEL_INFO,
  url,
  getModelInfoFromResponse,
  getOptions,
  getOptionLabel = m => m,
  getValue,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const efm = new EffectManager();

  const updateModelInfo = async () => {
    let d = EMPTY_MODEL_INFO;
    setModelInfo(d); // in case of request error this resets modelInfo
    if (!url)
      return;
    const x = await getData(url, dispatch, data => {
      d = getModelInfoFromResponse(data) || EMPTY_MODEL_INFO;
    }, {
      request_manager: efm,
    });
    if (efm.cancelled)
      return;
    setModelInfo(d);
  };

  useEffect(() => { const x = async () => updateModelInfo(); x(); return efm.cancel }, [url]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      className={classes.textField}
      options={getOptions(modelInfo)}
      getOptionLabel={m => modelInfo ? getOptionLabel(m, modelInfo) : t('common.loading')}
      value={getValue(modelInfo)}
      onChange={onChange}
      renderInput={params =>
        <TextField {...params} variant="outlined" label={t("projects.model")}/>
      }
    />
  );
}

export function SimilaritySettings({ settings = {}, setSettings, server }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const [simConfDetails, setSimConfDetails] = useState();

  const onChange = name => (event, value) => {
    const other = name == 'model' ? { partition: null } : {};
    setSettings(name, { is_direct: true })({ [name]: value, ...other });
  };

  return (
    <GridList className={classes.gridList} cols={1}>
      <GridListTile cols={1} style={{ height: "auto" }}>
        <SelectModel
          settings={settings}
          onChange={onChange('model')}
          modelInfo={simConfDetails}
          setModelInfo={setSimConfDetails}
          EMPTY_MODEL_INFO={EMPTY_SIM_CONFIG_DETAILS}
          url={server && `/api/settings/similarity_config_details?server=${server}`}
          getModelInfoFromResponse={data => data.similarity_config_details}
          getOptions={modelInfo => Object.keys(modelInfo?.models || {}).filter(m => modelInfo.models[m].type != 'rasa')}
          getOptionLabel={(m, modelInfo) => {
            const cps = modelInfo.models[m]?.cluster_partitions;
            return cps && cps.length ? m +` _ _ _ _ _ [${t("projects.cluster_partitions")}: `
              + cps.join(', ') +']' : m;
          }}
          getValue={modelInfo => modelInfo?.models[settings?.model] && settings?.model || null}
        />
      </GridListTile>
      <GridListTile cols={1} style={{ height: "auto" }}>
        <Autocomplete
          size="small"
          fullWidth
          options={
            (settings?.model && simConfDetails
              && simConfDetails.models[settings?.model]?.cluster_partitions || [])
              .sort(cmpPossibleNumbers)
          }
          className={classes.textField}
          value={String(settings?.partition ?? '') || null}
          renderInput={params =>
            <TextField {...params} variant="outlined" label={t("projects.cluster_partitions")}/>
          }
          onChange={onChange("partition")}
        />
      </GridListTile>
      <GridListTile cols={1} style={{ height: "auto" }}>
        <NumberField
          label={t("settings.sim_limit")}
          className={classes.textField}
          value={settings?.max || ''}
          inputProps={{ min: 0 }}
          onChange={setSettings("max")}
        />
      </GridListTile>
      <GridListTile cols={1} style={{ height: "auto" }}>
        <NumberField
          label={t("settings.sim_threshold")}
          className={classes.textField}
          value={settings?.threshold ?? ''}
          inputProps={{ min: 0, max: 1, step: '.1' }}
          onChange={setSettings("threshold")}
        />
      </GridListTile>
    </GridList>
  );
}

export const ImportFromDCSettings = FieldSetSettings;

