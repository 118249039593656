function genConsts(keys, _case = 'camel', pref = '') {
  // input: aLL_Variants_OF_snake_case
  const snake = v => v.toLowerCase();
  const camel = v => snake(v).replace(/_\w/g, m=>m.slice(1).toUpperCase());
  const pascal = v => camel(v).replace(/^\w/, m=>m.toUpperCase());
  const same = v => v;
  const transform = { pascal, camel, snake, same };
  const cs = {};
  keys.forEach(k => { cs[k] = pref + transform[_case](k) });
  return Object.freeze(cs);
};

const genACOptsFromArray = consts => consts.map(name => ({ name, value: name }));
const genAutocompleteOptions = consts => genACOptsFromArray(Object.keys(consts));

/* ============================ CONSTS ============================== */

export const LANG = {
    RU: 'ru-RU',
    EN: 'en-US',
};

export const INTERFACE_LOC ={
    'en-US':{
        mainListItems:{
            projects: 'Projects',
            intents: 'Intents',
            slots:'Slots',
            entities:'Entities',
            annotation:'Annotation',
            train:'Train',
            tests:'Tests',
        },
        buttons:{
            disagree:'Disagree',
            agree:'Agree',
        }
    }
};

export const MESSAGE_STATUS = genConsts([ 'ERROR', 'SUCCESS', 'WARNING', 'INFO' ]);

export const SOME_SETTINGS = {
    TOKEN: 'token_03703751-541b-4c2c-a223-a5aef0e06d3a',
};

export const DIALOG_USER_STATE = genConsts([ 'DISAGREE', 'AGREE', 'NEW_ITEM' ]);
export const FILE_FORMATS = genConsts([ 'JSON', 'CSV', 'YAML' ]);

export const ENTITYLIST = genConsts([
    'ADD_ENTITY',
    'ADD_VALUE',
    'ADD_SYNONYM',
    'UPDATE_VALUE',
    'UPDATE_SYNONYM',
    'UPDATE_ENTITY',
    'UPLOAD_ENTITY',
], 'snake');

export const BTN = genConsts([ 'CANCEL', 'CREATE', 'SAVE', 'TRAIN', 'TRAIN_TEST', 'APPLY' ], 'pascal', 'btn');

export const PROJECT_TYPES = genConsts([ 'NLU', 'ASR', 'GAI' ], 'same');
export const PROJECT_TYPE_AC_OPTS = genAutocompleteOptions(PROJECT_TYPES);

export const NLU_TYPES = genConsts([ 'NLU_NB', 'NLU_RASA', 'ASR_E2E' ], 'same');
export const NLU_TYPE_AC_OPTS = genAutocompleteOptions(NLU_TYPES);

// console.log({ NLU_TYPES, MESSAGE_STATUS, DIALOG_USER_STATE, FILE_FORMATS, ENTITYLIST, BTN });

export const GENV_METRICS = [ "rouge1", "rouge2", "rougeL", "rougeLsum", "bleu_score", "accuracy" ];
export const GENV_METRICS_AC_OPTS = genACOptsFromArray(GENV_METRICS);

export const COLORS = [
    '255, 255, 11',
    '79,  165, 246',
    '238, 127, 240',
    '122, 204, 34',
    '251, 149, 54',
    '27,  252, 248',
    '242, 33,  33',
    '255, 227, 150',
    '27,  82,  160',
    '200, 50,  240',
    '75,  239, 50',
    '214, 242, 60',
    '204, 196, 252',
    '255, 141, 71',
].map(c => 'RGBA('+ c +', 0.3)');

export const LookupScope = Object.freeze({
    Custom: 'custom',
    Public: 'public',
});
